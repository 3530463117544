<template>
  <div class="col-12 mt-4">
    <h3 class="txt-primary mb-4 txt-mob-center">What I do</h3>
    <p>
      What I do daily is quite broad. My responsibilities go from assessing the
      feasibility of a project and managing the company’s digital strategy to
      developing and maintaining websites and web tools. Having an efficient
      informational watch is a crucial point of my job. It is my responsibility
      to stay up-to-date with any information regarding the RDPR, the
      technologies that I use, and security issues, to name a few.
    </p>
    <p>Here are a few examples of what I’ve been doing lately:</p>
    <ul class="about-details-list">
      <li>
        <span class="txt-bold">
          Design, development, and maintenance of web tools</span
        >, mainly with Vue, Node, Express and MariaDB.
      </li>
      <li>
        <span class="txt-bold"
          >Design, creation, maintenance, Search Engine Optimization, and
          analytics implementation</span
        >
        of a few conference and showcase websites.
      </li>
      <li>
        <span class="txt-bold"
          >Management of the company’s digital strategy</span
        >
        and coordination of the communication strategy.
      </li>
      <li>
        <span class="txt-bold">Assessment, planning, and organization</span> of
        future projects as well as new versions of existing projects.
      </li>
      <li>
        <span class="txt-bold"
          >Migration of Google Analytics to self-hosted Matomo:</span
        >
        In February 2022, the CNIL and its European counterparts analyzed the
        conditions under which the data collected through Google Analytics is
        transferred to the United States and considered illegals. Under these
        conditions, I deemed it safer to remove Google Analytics and switch
        analytics and performance data collections to Matomo.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'InDetails',
};
</script>

<style lang="scss">
.about-details-list {
  li {
    list-style: disc;
  }
}
</style>
