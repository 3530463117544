<template>
  <div class="col-12 col-lg-7 mb-4">
    <h3 class="txt-primary mb-4 txt-mob-center">In short</h3>
    <p>
      Chief Technical Officer and fullstack web developer at
      <a
        href="https://weloop.org"
        target="_blank"
        rel="noreferrer"
        class="inline-link"
        >WeLOOP</a
      >, I have the privilege of working on many projects for various clients.
      Each project is significantly different from the next one, allowing me to
      broaden my knowledge and skills. However, they all have in common the
      focus on building accessible and inclusive products conceived in a
      user-first mindset.
    </p>
    <p>
      Most of these projects are focused on making Life Cycle Assessment and
      environmental issues accessible to a broader spectrum. In keeping with
      this theme, I make a point of taking an eco-design approach to web
      services conception.
    </p>
    <div class="btn-div">
      <router-link :to="{ name: 'Projects' }">
        <button class="app-btn mt-4">See my work</button></router-link
      >
    </div>
  </div>
  <div class="col-12 col-lg-5 about-img mb-4 active">
    <div class="img-bg active"></div>
    <img
      class="img-fluid"
      :src="imgUrl"
      alt="Alison Vandromme Photo"
    />
  </div>
</template>

<script>
import Api from '@/utils/services/Api';

export default {
  name: 'InShort',
  data() {
    return {
      imgUrl: ''
    }
  },
  created() {
    this.imgUrl = Api.getUrl('images', 'avandromme.jpg')
  }
};
</script>
