<template>
  <div class="col-12 mt-4">
    <h3 class="txt-primary mb-4 txt-mob-center">What I focus on</h3>
    <p>
      According to
      <a
        href="https://www.statista.com/topics/1002/mobile-app-usage/"
        target="_blank"
        rel="noreferrer"
        class="inline-link"
        >Statista</a
      >, more than 50% of web traffic comes from mobile. Hence, conceiving web
      services with a mobile-first approach is necessary for providing users
      with a fantastic experience. This approach goes further than simply
      designing a mobile-friendly web service and goes hand in hand with
      user-first, web quality, and eco-design concepts.
    </p>
    <p>
      The challenge that I aim to fulfill for each application and website I
      build is finding a balance between these concepts. An eco-designed web
      service does not have to be boring: Interactions, animations, and visual
      components are part of a good user experience. By making choices and
      compromises, we can build an exceptional user experience while limiting
      its impact on our planet.
    </p>
  </div>
</template>

<script>
export default {
  name: 'FocusPoints',
};
</script>