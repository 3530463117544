<template>
  <div class="row about">
    <div class="col-12 section-title active slide-down-d0">
      <h2>About me</h2>
    </div>
  </div>
  <div class="row about fade-in-medium">
    <in-short />
    <in-details />
    <focus-points />
  </div>
</template>

<script>
import InShort from '@/components/about/InShort';
import InDetails from '@/components/about/InDetails';
import FocusPoints from '@/components/about/FocusPoints';

export default {
  name: 'About',
  components: {
    InShort,
    InDetails,
    FocusPoints,
  },
};
</script>
